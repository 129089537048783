function NumbersPage() {
  return (
    <section className={"tm-content"}>
        <h2 className={"mb-5 tm-content-title"}>Numbers</h2>
        <p className={"mb-5"}>This page will include some stats about me</p>
    </section>
);
}

export default NumbersPage;
